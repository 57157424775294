import { OPERATOR_SERVICE_CENTER_RESTRICTIONS_MESSAGE } from '../../../pages/OperatorServiceCenter/enums/operator-service-center-restrictions.enum'
import { OperatorServiceCenterBorrowerResponse } from '../../../services/bff/operatorServiceCenter/dtos/OperatorServiceCenter.dto'
import { BorrowerStatus } from '../application/application.types'

export class OperatorServiceCenterValidator {
  static isBorrowerInPreApplication(borrowerProfile: OperatorServiceCenterBorrowerResponse): boolean {
    return [
      BorrowerStatus.PreApplicationApproved,
      BorrowerStatus.PreApplicationDenied,
      BorrowerStatus.Incomplete,
      BorrowerStatus.PreApplicationContinue,
    ].includes(borrowerProfile.application.status)
  }

  static validateBorrowerApplicationStatus(borrower: OperatorServiceCenterBorrowerResponse): string | null {
    switch (borrower?.application?.status) {
      case BorrowerStatus.Denied:
        return OPERATOR_SERVICE_CENTER_RESTRICTIONS_MESSAGE.BORROWER_APPLICATION_DENIED
      case BorrowerStatus.PreApproved:
        return OPERATOR_SERVICE_CENTER_RESTRICTIONS_MESSAGE.BORROWER_APPLICATION_PRE_APPROVED
      case BorrowerStatus.UnderAnalysis:
        return OPERATOR_SERVICE_CENTER_RESTRICTIONS_MESSAGE.BORROWER_APPLICATION_UNDER_ANALYSIS
      case BorrowerStatus.ManualAnalysis:
        return OPERATOR_SERVICE_CENTER_RESTRICTIONS_MESSAGE.BORROWER_APPLICATION_MANUAL_ANALYSIS
    }

    return null
  }

  static validateBorrowerPurchaseEligibility(borrower: OperatorServiceCenterBorrowerResponse): string | null {
    if (borrower?.credit?.disablePurchase) {
      return OPERATOR_SERVICE_CENTER_RESTRICTIONS_MESSAGE.BORROWER_BLOCKED_TO_PURCHASE
    }
    if (borrower?.credit?.lateDays && borrower?.credit?.lateDays > 0) {
      return OPERATOR_SERVICE_CENTER_RESTRICTIONS_MESSAGE.OVERDUE_INSTALLMENTS
    }

    return null
  }
}
