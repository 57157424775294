import SafeListService from '../../common/assets/utils/SafeListService'
import { bffParceirosApiService } from '../../services/bff'
import { ApplicationStatus } from '../../services/bff/applicationEngine/dto/Application.dto'
import { sleep } from '../../utils/sleep'

export default class TransactionalCreditHelper {
  static REEVALUATION_INTERVAL_CHECK = 1000

  static async runPreChecks(document: string, storeId: string) {
    if (!SafeListService.shouldRunApplicationReavaluationStoresSafeList(storeId)) {
      return
    }

    const preApplication = await bffParceirosApiService.applicationEngine.preApplication({
      cpf: document,
      storeId,
    })

    const borrowerId = preApplication?.borrower?.id
    if (!borrowerId) {
      return
    }

    if (preApplication.shouldReevaluate) {
      await this.handleReevaluation(borrowerId, storeId)
    }

    if (preApplication.application?.status === ApplicationStatus.UNDER_ANALYSIS) {
      await this.handleApplicationInProgress(borrowerId)
    }
  }

  static async handleReevaluation(borrowerId: string, storeId: string) {
    await bffParceirosApiService.applicationEngine.reevaluateBorrower({ borrowerId, storeId })
    await this.handleApplicationInProgress(borrowerId)
  }

  static async handleApplicationInProgress(borrowerId: string) {
    await sleep(this.REEVALUATION_INTERVAL_CHECK)

    let applicationStatus = await this.getApplicationStatus(borrowerId)
    while (applicationStatus === ApplicationStatus.UNDER_ANALYSIS.toString()) {
      await sleep(this.REEVALUATION_INTERVAL_CHECK)
      applicationStatus = await this.getApplicationStatus(borrowerId)
    }
  }

  static async getApplicationStatus(borrowerId: string) {
    const reevaluationStatus = await bffParceirosApiService.applicationEngine.getApplicationStatus(borrowerId)
    return reevaluationStatus.status
  }
}
