import { PayloadAction } from '@reduxjs/toolkit'
import { put, takeLatest } from 'redux-saga/effects'
import { history } from '../../../App'
import { PagesPathEnum } from '../../../pages/enums/pages-path.enum'
import { OPERATOR_SERVICE_CENTER_PAGES } from '../../../pages/OperatorServiceCenter/enums/operator-service-center-pages.enum'
import { bffParceirosApiService } from '../../../services/bff'
import { OperatorServiceCenterBorrowerResponse } from '../../../services/bff/operatorServiceCenter/dtos/OperatorServiceCenter.dto'
import ApplicationSliceReducer from '../application/application.reducer'
import OperatorServiceCenterSliceReducer, { IFetchBorrowerPayload } from './operator-service-center.reducer'
import { OperatorServiceCenterErrorHandler } from './OperatorServiceCenterErrorHandler'
import { OperatorServiceCenterValidator } from './OperatorServiceCenterValidator'

function* fetchBorrower(action: PayloadAction<IFetchBorrowerPayload>) {
  const { document, storeId } = action.payload

  try {
    yield put(OperatorServiceCenterSliceReducer.actions.setDocument(document))

    const response: OperatorServiceCenterBorrowerResponse = yield bffParceirosApiService.operatorServiceCenter.fetchBorrower(
      document,
      storeId
    )

    yield put(OperatorServiceCenterSliceReducer.actions.fetchBorrowerSuccess(response))

    const borrowerApplicationRestriction = OperatorServiceCenterValidator.validateBorrowerApplicationStatus(response!)
    const borrowerIsDisableToPurchase = OperatorServiceCenterValidator.validateBorrowerPurchaseEligibility(response!)
    const isBorrowerInPreApplication = OperatorServiceCenterValidator.isBorrowerInPreApplication(response)

    if (isBorrowerInPreApplication) {
      yield put(ApplicationSliceReducer.actions.resetState())
      yield put(ApplicationSliceReducer.actions.fetchPreApplication({ cpf: document }))
      history.push(PagesPathEnum.APPLICATION.replace(':cpf', document))
    } else if (borrowerApplicationRestriction) {
      yield put(OperatorServiceCenterSliceReducer.actions.setBorrowerRestriction(borrowerApplicationRestriction))
      yield put(
        OperatorServiceCenterSliceReducer.actions.setPage(OPERATOR_SERVICE_CENTER_PAGES.BORROWER_RESTRICTION_PAGE)
      )
    } else if (borrowerIsDisableToPurchase) {
      yield put(OperatorServiceCenterSliceReducer.actions.setBorrowerRestriction(borrowerIsDisableToPurchase))
      yield put(
        OperatorServiceCenterSliceReducer.actions.setPage(OPERATOR_SERVICE_CENTER_PAGES.BORROWER_RESTRICTION_PAGE)
      )
    } else {
      yield put(OperatorServiceCenterSliceReducer.actions.setPage(OPERATOR_SERVICE_CENTER_PAGES.BORROWER_PAGE))
    }
  } catch (error) {
    yield put(OperatorServiceCenterSliceReducer.actions.fetchBorrowerError(error))

    OperatorServiceCenterErrorHandler.handleError(error, document)
  }
}

const operatorServiceCenterSaga = [takeLatest(OperatorServiceCenterSliceReducer.actions.fetchBorrower, fetchBorrower)]

export default operatorServiceCenterSaga
