import { createTheme, ThemeProvider } from '@material-ui/core'
import React from 'react'
import OperatorServiceCenterBorrowerData from '../../organisms/OperatorServiceCenter/OperatorServiceCenterBorrowerData'
import OperatorServiceCenterBorrowerRestriction from '../../organisms/OperatorServiceCenter/OperatorServiceCenterBorrowerRestriction'
import OperatorServiceCenterDocumentVerification from '../../organisms/OperatorServiceCenter/OperatorServiceCenterDocumentVerification'
import OperatorServiceCenterPreApplicationResultPage from '../../organisms/OperatorServiceCenter/OperatorServiceCenterPreApplicationResultPage'
import { useTypedSelector } from '../../redux/reducers/selectors'
import { PhoneLayout } from '../../ui/PhoneLayout'
import { OPERATOR_SERVICE_CENTER_PAGES } from './enums/operator-service-center-pages.enum'

const theme = createTheme({
  palette: {
    primary: {
      main: '#11B048',
    },
  },
})

const OperatorServiceCenter: React.FC = () => {
  const { page } = useTypedSelector(state => state.operatorServiceCenter)

  const renderPage = () => {
    switch (page) {
      case OPERATOR_SERVICE_CENTER_PAGES.DOCUMENT_PAGE:
        return <OperatorServiceCenterDocumentVerification />
      case OPERATOR_SERVICE_CENTER_PAGES.BORROWER_RESTRICTION_PAGE:
        return <OperatorServiceCenterBorrowerRestriction />
      case OPERATOR_SERVICE_CENTER_PAGES.BORROWER_PAGE:
        return <OperatorServiceCenterBorrowerData />
      case OPERATOR_SERVICE_CENTER_PAGES.PRE_APPLICATION_RESULT_PAGE:
        return <OperatorServiceCenterPreApplicationResultPage />
    }
  }

  return (
    <ThemeProvider theme={theme}>
      <PhoneLayout>{renderPage()}</PhoneLayout>
    </ThemeProvider>
  )
}

export default OperatorServiceCenter
