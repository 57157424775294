import axios, { AxiosInstance } from 'axios'
import { getAxiosInstance } from '../utils'
import { ApplicationDto, ICreateApplication } from './dto/Application.dto'
import { IGetBorrowerApplicationStatus } from './dto/ApplicationStatus.dto'
import { IPostPreApplication, PreApplicationDto } from './dto/PreApplication.dto'
import { ReevaluationDto, ReevaluationRequest } from './dto/Reevaluation.dto'

export default class ApplicationEngineService {
  private axiosInstance: AxiosInstance

  constructor() {
    this.axiosInstance = axios.create()
  }

  _init(baseURL: string, token: string | null): void {
    this.axiosInstance = getAxiosInstance({
      baseURL,
      token,
    })
  }

  async getApplicationStatus(borrowerId: string): Promise<IGetBorrowerApplicationStatus> {
    const response = await this.axiosInstance.get<IGetBorrowerApplicationStatus>(
      `application-engine/borrowers/${borrowerId}/applications/status`
    )

    return response.data
  }

  async preApplication(body: IPostPreApplication): Promise<PreApplicationDto> {
    const response = await this.axiosInstance.post<PreApplicationDto>(`application-engine/pre-application`, body)
    return response.data
  }

  async startApplicationEngine(application: ICreateApplication): Promise<ApplicationDto> {
    const response = await this.axiosInstance.post<ApplicationDto>(`application-engine/application`, application)

    return response.data
  }

  async reevaluateBorrower(request: ReevaluationRequest): Promise<ReevaluationDto> {
    const response = await this.axiosInstance.post<ReevaluationDto>(`application-engine/reevaluate`, request)

    return response.data
  }
}
