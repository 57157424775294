import { ILoginProfileResponse } from '@scudraservicos/coordinator-client/dist/src/services/auth/interfaces/GetMe.res.interface'
import { Box } from 'grommet'
import { Money as GrommetMoneyIcon, Optimize } from 'grommet-icons'
import * as React from 'react'
import { useDispatch } from 'react-redux'
import { HistoryProps } from '../../../../App'
import { isServiceCentralAllowed, isSmartphoneSaleAllowed, isTransactionalCreditAllowed } from '../../../../common/AccessHelper'
import FeatureFlags from '../../../../common/assets/utils/featureFlags'
import { Role } from '../../../../common/constants'
import { isReportsPageActive } from '../../../../config/config'
import ConfigurationsSliceReducer from '../../../../redux/reducers/configurations/configurations.reducer'
import { useTypedSelector } from '../../../../redux/reducers/selectors'
import { Calculator, Dollar, DollarNew, FilePlus, Group, Logout, Money, Settings } from '../../../../ui/Icons'
import DashboardIcon from '../../../../ui/Icons/DashboardIcon'
import HouseIcon from '../../../../ui/Icons/House'
import HouseNewIcon from '../../../../ui/Icons/HouseNew'
import SmartphoneIcon from '../../../../ui/Icons/Smartphone'
import NavBarDesktop from '../NavBarDesktop/NavBarDesktop'
import NavBarMobile from '../NavBarMobile/NavBarMobile'
import { NavBarComponent } from './style'

export type NavItem = {
  id?: NavItemEnum
  label: string
  path: string
  icon: JSX.Element
  authorizedRoles: Role[]
  retailers?: string[]
}

export enum NavItemEnum {
  LOGOUT = 'LOGOUT',
  BUDGET = 'BUDGET',
  TRANSACTIONAL_CREDIT = 'TRANSACTIONAL_CREDIT',
}

export const PAGES_ALLOWED = (pages: {
  isReportPageActive: boolean
  transactionalCredit: { isAllowed: boolean; shouldHighlightIcon: boolean }
  operatorServiceCenter: { isAllowed: boolean; shouldHighlightIcon: boolean }
  smartphoneSale: { isAllowed: boolean; shouldHighlightIcon: boolean }
}): NavItem[] => {
  const items = [
    {
      label: 'Cadastro',
      path: '/central-de-vendas',
      icon: <FilePlus />,
      // retailers: ALLOWED_RETAILER_ON_APPLICATION_V2_PAGE,
      authorizedRoles: [Role.ANALYST, Role.MANAGER, Role.REPRESENTATIVE, Role.ATTENDANT, Role.PROMOTER],
    },
    {
      label: 'Caixa',
      path: '/caixa',
      icon: <Money />,
      authorizedRoles: [Role.ANALYST, Role.MANAGER, Role.CASHIER, Role.ATTENDANT],
    },
    {
      id: NavItemEnum.BUDGET,
      label: 'Calculadora de parcelas',
      path: '/calculadora',
      icon: <Calculator />,
      authorizedRoles: [Role.ANALYST, Role.MANAGER, Role.REPRESENTATIVE, Role.ATTENDANT, Role.PROMOTER],
    },
  ] as NavItem[]
  if (pages.transactionalCredit.isAllowed) {
    const enableTransactionalCreditOnServiceCentral = FeatureFlags.enableTransactionalCreditOnServiceCentral()
    items.push({
      id: NavItemEnum.TRANSACTIONAL_CREDIT,
      label: enableTransactionalCreditOnServiceCentral ? 'Central de Vendas' : 'Super Compra',
      path: '/super-compra',
      icon: enableTransactionalCreditOnServiceCentral ? (
        pages.operatorServiceCenter.shouldHighlightIcon ? (
          <HouseNewIcon />
        ) : (
          <HouseIcon />
        )
      ) : pages.transactionalCredit.shouldHighlightIcon ? (
        <DollarNew />
      ) : (
        <Dollar />
      ),
      authorizedRoles: [
        Role.CASHIER,
        Role.MANAGER,
        Role.ATTENDANT,
        Role.ANALYST,
        Role.RETAILER_ANALYST,
        Role.REPRESENTATIVE,
        Role.PROMOTER,
      ],
    })
  }
  if (pages.operatorServiceCenter.isAllowed) {
    items.push({
      label: 'Central de Vendas',
      path: '/central-de-vendas',
      // TODO: Ajust icon color on black and white backgrounds
      icon: pages.operatorServiceCenter.shouldHighlightIcon ? <HouseNewIcon /> : <HouseIcon />,
      authorizedRoles: [
        Role.MANAGER,
        Role.ATTENDANT,
        Role.ANALYST,
        Role.RETAILER_ANALYST,
        Role.REPRESENTATIVE,
        Role.PROMOTER,
      ],
    })
  }
  if (pages.smartphoneSale.isAllowed) {
    items.push({
      label: 'Venda de celular',
      path: '/venda-celular',
      icon: <SmartphoneIcon />,
      authorizedRoles: [Role.CASHIER, Role.MANAGER, Role.ANALYST, Role.RETAILER_ANALYST],
    })
  }
  items.push(
    ...[
      {
        label: 'Estatísticas',
        path: '/dashboard',
        icon: <DashboardIcon />,
        authorizedRoles: [
          Role.REPRESENTATIVE,
          Role.CASHIER,
          Role.MANAGER,
          Role.ATTENDANT,
          Role.ANALYST,
          Role.RETAILER_ANALYST,
          Role.PROMOTER,
        ],
      },
      {
        label: 'Colaboradores',
        path: '/colaboradores',
        icon: <Group />,
        authorizedRoles: [Role.ANALYST, Role.MANAGER],
      },
      {
        label: 'Financeiro',
        path: '/financial/originations',
        icon: <GrommetMoneyIcon />,
        authorizedRoles: [Role.ANALYST, Role.RETAILER_ANALYST],
      },
      {
        label: 'Configurações',
        path: '/configuracao',
        icon: <Settings />,
        authorizedRoles: [Role.ANALYST, Role.MANAGER, Role.ATTENDANT, Role.REPRESENTATIVE, Role.CASHIER, Role.PROMOTER],
      },
    ]
  )

  if (pages.isReportPageActive) {
    items.push({
      label: 'Relatórios',
      path: '/relatorios',
      icon: <Optimize />,
      authorizedRoles: [Role.MANAGER, Role.RETAILER_ANALYST, Role.CASHIER],
    })
  }
  items.push({
    id: NavItemEnum.LOGOUT,
    label: 'Sair',
    path: '/',
    icon: <Logout />,
    authorizedRoles: [
      Role.ANALYST,
      Role.MANAGER,
      Role.REPRESENTATIVE,
      Role.CASHIER,
      Role.ATTENDANT,
      Role.RETAILER_ANALYST,
      Role.PROMOTER,
    ],
  })
  return items
}

export interface IResponsiveHeaderProps extends HistoryProps {
  user: ILoginProfileResponse
}

const ResponsiveHeader = (props: IResponsiveHeaderProps) => {
  const dispatch = useDispatch()
  const { loadingConfigurations, configurations, hasFetchedConfigurations } = useTypedSelector(state => ({
    loadingConfigurations: state.configurations.isLoadingConfigurations,
    configurations: state.configurations.configurations,
    hasFetchedConfigurations: state.configurations.hasFetchedConfigurations,
  }))

  React.useEffect(() => {
    if (!hasFetchedConfigurations) {
      loadConfigurations()
    }
  }, [])

  const loadConfigurations = () => {
    dispatch(ConfigurationsSliceReducer.actions.fetchConfigurations({}))
  }

  if (loadingConfigurations) return <></>

  const { user } = props

  const userRole = user.role as Role

  const navItems = PAGES_ALLOWED({
    isReportPageActive: isReportsPageActive,
    transactionalCredit: { isAllowed: isTransactionalCreditAllowed(configurations), shouldHighlightIcon: false },
    operatorServiceCenter: { isAllowed: isServiceCentralAllowed(configurations), shouldHighlightIcon: false },
    smartphoneSale: { isAllowed: isSmartphoneSaleAllowed(configurations), shouldHighlightIcon: true },
  })

  return (
    <NavBarComponent>
      <Box className={'is-mobile'}>
        <NavBarMobile history={props.history} userRole={userRole} navItems={navItems}></NavBarMobile>
      </Box>
      <Box className={'panel-header'}>
        <NavBarDesktop history={props.history} userRole={userRole} navItems={navItems} />
      </Box>
    </NavBarComponent>
  )
}

export default ResponsiveHeader
