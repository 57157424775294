import React from 'react'
import { useDispatch } from 'react-redux'
import styled from 'styled-components'
import { history } from '../../App'
import { PagesPathEnum } from '../../pages/enums/pages-path.enum'
import ApplicationSliceReducer from '../../redux/reducers/application/application.reducer'
import { BorrowerStatus } from '../../redux/reducers/application/application.types'
import OperatorServiceCenterSliceReducer from '../../redux/reducers/operator-service-center/operator-service-center.reducer'
import { useTypedSelector } from '../../redux/reducers/selectors'
import { green, red } from '../../ui/utils/_colors'

const OperatorServiceCenterPreApplicationResultPage = () => {
  const dispatch = useDispatch()

  const { preApplicationStatus, document } = useTypedSelector(state => ({
    preApplicationStatus: state.operatorServiceCenter.preApplicationStatus,
    document: state.operatorServiceCenter.document,
  }))

  const handleBackButton = () => {
    dispatch(OperatorServiceCenterSliceReducer.actions.resetRequest())
    return history.push(PagesPathEnum.OPERATOR_SERVICE_CENTER)
  }

  const handleGoToApplicationButton = () => {
    history.push(PagesPathEnum.APPLICATION.replace(':cpf', document!))
    dispatch(ApplicationSliceReducer.actions.resetState())
    dispatch(ApplicationSliceReducer.actions.fetchPreApplication({ cpf: document! }))
  }

  const ApprovedPreApplication = (
    <MainContainer>
      <h1>
        Este cliente tem <br />
        <GreenText>crédito pré-aprovado!</GreenText>
      </h1>
      <p>Realize o cadastro para liberar o seu limite.</p>
    </MainContainer>
  )

  const DeniedPreApplication = (
    <MainContainer>
      <h1>
        <RedText>Infelizmente não podemos ceder crédito</RedText> a este cliente no momento :(
      </h1>
    </MainContainer>
  )

  const wasPreApplicationApproved = preApplicationStatus === BorrowerStatus.PreApplicationApproved

  return (
    <>
      {wasPreApplicationApproved ? ApprovedPreApplication : DeniedPreApplication}
      <Footer>
        {wasPreApplicationApproved && (
          <ConfirmButton onClick={handleGoToApplicationButton}>Realizar cadastro</ConfirmButton>
        )}
        <BackButton onClick={handleBackButton}>Voltar</BackButton>
      </Footer>
    </>
  )
}

export default OperatorServiceCenterPreApplicationResultPage

const MainContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  overflow-y: auto;
  height: calc(100% - 56px);
  width: 100%;
  padding: 10px 0px;
`

const GreenText = styled.span`
  color: ${green.dark1};
`

const RedText = styled.span`
  color: ${red.dark};
`

const Footer = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 2vh;
`

const ConfirmButton = styled.button`
  margin-top: 2vh;
  background: ${(props: any) => (props && props.disabled ? '#a9e1bc' : '#12AA6A')};
  border-radius: 10px;
  border: 0px;
  padding: 12px;
  height: 60px;
  width: 100%;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: #ffffff;
`

const BackButton = styled.button`
  margin-top: 2vh;
  background: #ffffff;
  border-radius: 10px;
  border: 1px solid ${green.dark1};
  padding: 12px;
  height: 60px;
  width: 100%;

  font-family: Roboto;
  font-style: normal;
  font-weight: bold;
  font-size: 16px;
  line-height: 16px;
  text-align: center;
  color: ${green.dark1};
`
